@import "../../scss/variables";

@keyframes highlights {
    0% {
        fill: rgba($color-highlight, 0.4);
    }
    50% {
        fill: rgba($color-highlight, 0.9);
    }
    100% {
        fill: rgba($color-highlight, 0.4);
    }
}

@keyframes highlights-red {
    0% {
        fill: rgba(red, 0.4);
    }
    50% {
        fill: rgba(red, 0.9);
    }
    100% {
        fill: rgba(red, 0.4);
    }
}

@keyframes highlights-orange {
    0% {
        fill: rgba(orange, 0.4);
    }
    50% {
        fill: rgba(orange, 0.9);
    }
    100% {
        fill: rgba(orange, 0.4);
    }
}

.viewer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
    background-color: $color-grey-0;

    &.hide {
        display: none;
    }

    &__logo {
        position: fixed;
        left: 1rem;
        top: 1rem;
        width: 5rem;
        z-index: 110;
    }

    &__images {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-in-out;
        user-select: none;
        z-index: 2;

        img {
            position: absolute;
            left: 0;
            opacity: 0;
            user-select: none;
            width: 100%;
            height: 100%;
        }

        &.night {
            opacity: 0;
            z-index: 1;
        }
    }

    &__elements {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            // transform: translate3d(0, 0, 0);
        }
    }

    &__element {
        fill: rgba($color-secondary, 0.5);
        stroke: rgba($color-primary, 1);
        stroke-width: 2.4;
        // z-index: 5;
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover,
        &.is-hover {
            fill: rgba($color-primary, 0.8);
        }

        &.is-reserved {
            fill: rgba(orange, 0.3);
            stroke: rgba(orange, 1);

            &:hover,
            &.is-hover {
                fill: rgba(orange, 0.8);
            }
        }
        &.is-sold {
            fill: rgba(red, 0.3);
            stroke: rgba(red, 1);

            &:hover,
            &.is-hover {
                fill: rgba(red, 0.8);
            }
        }

        &.is-highlight {
            fill: rgba($color-highlight, 0.8);
            animation-name: highlights;
            animation-iteration-count: infinite;
            animation-duration: 2s;

            &.is-reserved {
                fill: rgba(orange, 0.3);
                animation-name: highlights-orange;
            }

            &.is-sold {
                fill: rgba(red, 0.3);
                animation-name: highlights-red;
            }
        }
    }

    &__images-wrapper {
        width: 100%;
        flex: 0 0 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;

        @include landscape-mode {
            height: 100%;
            width: 100%;
        }
    }

    &__images-container {
        // height: 100%;
        // width: 100vh * 4/ 3;

        width: 100%;
        flex: 0 0 100%;
        height: 100vw * (3/4);

        transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
        position: relative;
        z-index: 1;
        touch-action: none;

        order: 1;

        @include landscape-mode {
            height: 100%;
            width: 100vh * (4/3);
            position: absolute;
        }

        &.hide {
            opacity: 0;
        }
        &.Night {
            .viewer {
                &__images {
                    &.day {
                        opacity: 0;
                    }
                    &.night {
                        opacity: 1;
                    }
                }
            }
        }

        @for $i from 0 through 300 {
            &.index-#{$i} {
                .viewer__images {
                    .image-#{$i} {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__loader {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__views-buttons {
        // position: absolute;
        width: 100%;
        // top: 1rem;
        left: 0;
        z-index: 4;
        // padding: 0 12rem 0 1rem;
        // display: flex;
        justify-content: flex-start;
        align-items: center;
        // flex-wrap: wrap;

        position: relative;
        order: 4;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        display: block;
        margin-top: 1rem;

        @include not-landscape-mode {
            border-top: 1px solid $color-grey-1;
        }

        @include landscape-mode {
            position: absolute;
            top: 1rem;
            justify-content: center;
            padding: 0 16rem 0 7rem;
            margin: 0;
        }

        &-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            padding: 1rem;
            position: relative;

            @include landscape-mode {
                justify-content: center;
                padding: 0 1rem 1rem;
                flex-wrap: wrap;
            }

            &::after {
                content: "";
                flex: 0 0 1px;
            }
        }

        .button {
            white-space: nowrap;
            margin: 0 1rem 0 0;

            @include landscape-mode {
                margin: 1rem 2rem 0 0;
            }
        }
    }

    &__right-buttons {
        position: absolute;
        // width: 10rem;
        top: 1rem;
        z-index: 5;

        position: relative;
        order: 3;
        display: flex;
        // width: 100%;
        align-items: center;
        justify-content: flex-start;
        padding: 0rem 0;
        flex: 0 0 16rem;

        @include landscape-mode {
            position: absolute;
            flex-direction: column;
            top: 1rem;
            right: 1rem;
            padding: 0;
        }
    }

    &__buttons-bottom {
        position: absolute;
        bottom: 0;
        padding: 1rem;
        z-index: 3;

        position: relative;
        order: 2;
        padding: 2rem 1.5rem 0;
        display: none;

        @include landscape-mode {
            position: absolute;
            bottom: 0;
            padding: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &__overlay-progress {
        font-size: 2rem;
    }

    &__support {
        position: absolute;
        // right: 2rem;
        // bottom: 1rem;
        width: 4rem;
        height: 4rem;
        // background-color: $color-white;
        box-shadow: $box-shadow-md;
        border-radius: 2rem;
        display: none;
        justify-content: center;
        align-items: center;
        font-size: 2.6rem;
        z-index: 22;

        position: relative;
        order: 3;
        margin: 1rem 1rem 0 0;

        @include landscape-mode {
            position: absolute;
            right: 2rem;
            bottom: 1rem;
            margin: 0;
        }
    }

    &__orientation {
        // right: 12rem;
        position: absolute;
        height: 4rem;
        width: 4rem;
        z-index: 20;
        // top: 2rem;
        right: 1rem;
        bottom: 1rem;
        display: none;

        // position: relative;
        order: 3;
        flex: 0 0 4rem;
        // margin: 2rem 2rem 0 0;

        @include landscape-mode {
            right: 12rem;
            top: 2rem;
            display: block;
        }

        &-circle {
            width: 4rem;
            height: 4rem;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            position: relative;

            &-label {
                width: 100%;
                height: 100%;
                background-color: $color-white;
                box-shadow: $box-shadow-s;
                border-radius: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $color-grey-7;
                font-weight: 500;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 1rem;
                height: 1rem;
                background-color: $color-primary;
                z-index: -1;
                left: 1.5rem;
                top: -0.4rem;
                transform: rotate(45deg);
                box-shadow: 0 0 0.5rem 0 $color-primary;
            }
        }
    }
}
