@import "../../scss/variables";

.collapse {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;

    &.is-loaded {
        transition: all 0.2s ease-out;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        width: 100%;
    }
}
