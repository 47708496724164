@import "../../../scss/variables";

.apartment-slice-features {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 1rem 0;
    flex: 1 0 300px;
    background-color: $color-grey-0;
    border: 1px solid $color-grey-2;
    display: flex;
    padding: 1rem 2rem;

    &__feature {
        flex: 1 0 240px;
        padding: 1rem 1rem 1rem 2rem;
        position: relative;
        font-size: 1.2rem;

        &::before {
            content: "";
            position: absolute;
            left: 0rem;
            height: 0.8rem;
            width: 0.8rem;
            top: 1.3rem;
            background-color: $color-primary;
        }
    }
}
