@import "../scss/variables";

.location {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  padding-top: $header-height-sm;

  @include landscape-mode {
    flex-direction: row;
    padding: 0;
  }

  &__left {
    z-index: 1;
    height: 70vh;

    @include landscape-mode {
      height: 100vh;
      position: sticky;
      top: 0;
      flex: 1 1 100vh * (4/3);
    }
  }

  &__right {
    z-index: 2;
    @include box-shadow-1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include landscape-mode {
      flex: 0 0 360px;
    }

    &-center {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include not-landscape-mode {
        max-width: 500px;
      }
    }
  }

  &__map {
    width: 100%;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: $header-height-md;
    padding: 0 1rem;
    background-color: $color-white;
    @include box-shadow-1;
    z-index: 3;

    @include landscape-mode {
      position: relative;
      box-shadow: none;
    }

    &-title {
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $color-black;
    }

    &-menu {
      width: 6rem;
      height: 6rem;
      @include ff-icon;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 4rem;
      cursor: pointer;
    }
  }

  &__search {
    display: flex;
    // border: 1px solid $color-primary;
    margin: 1rem;
    // padding: 1rem;

    &-icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 1rem 0 0;
      height: 16.5rem;
    }

    &-icon {
      @include ff-icon;
      text-align: center;
      display: flex;
      width: 100%;
      flex: 0 0 30px;
      align-items: center;
      justify-content: center;
      color: $color-primary;

      &.start {
        font-size: 1.8rem;
        flex: 0 0 24px;
      }
    }

    &-dot {
      $dot-size: 6px;
      width: $dot-size;
      height: $dot-size;
      background-color: $color-primary;
      border-radius: $dot-size/2;
      flex: 0 0 $dot-size;
      margin: 0.2rem 0;
    }

    &-content {
      flex: 1 0 50px;
      display: flex;
      flex-direction: column;
    }

    &-start {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: $color-black;
      border: 2px solid $color-primary;
      padding: 1rem;
      border-radius: 0.3rem;

      &-name {
        font-size: 2rem;
        font-weight: 500;
      }

      &-address {
        font-size: 1.4rem;
      }
    }

    &-end {
      display: flex;
      flex-direction: column;
      border: 2px solid $color-primary;
      padding: 1rem;
      border-radius: 0.3rem;
      position: relative;
      margin-top: 2rem;
    }

    &-input {
      display: flex;
      align-items: stretch;
      width: 100%;

      input {
        height: 4rem;
        border: 1px solid $color-primary;
        flex: 1 0 50px;
        font-size: 1.6rem;
        padding: 0.5rem;
        outline: none;
      }

      &-submit {
        @include ff-icon;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        border: none;
        color: $color-white;
        outline: none;
        cursor: pointer;
      }
    }

    &-divider {
      display: flex;
      align-items: center;
      padding: 1rem 0;

      &::before,
      &::after {
        content: "";
        flex: 1 0 1px;
        height: 1px;
        background-color: $color-primary;
      }

      &-text {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: $color-primary;
        padding: 0 1rem;
      }
    }

    &-pointer {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-label {
        font-size: 1.2rem;
        padding: 0 0 0 1rem;
        text-transform: uppercase;
      }

      &-icon {
        @include ff-icon;
        width: 4rem;
        height: 4rem;
        flex: 0 0 4rem;
        border: 1px solid $color-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-primary;
      }
    }

    &-error {
      margin: 1rem 1rem 1rem 4.5rem;
      border: 2px solid $color-sold;
      border-radius: 0.3rem;
      font-size: 1.4rem;
      padding: 1rem;
      color: $color-sold;
      text-transform: uppercase;
      text-align: center;
    }

    &-result {
      margin: 1rem 1rem 1rem 4.5rem;
      padding: 1rem;
      background-color: $color-primary;
      border-radius: 0.3rem;
      text-align: center;
      font-size: 2rem;
      font-weight: 500;
      color: $color-white;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 2rem;
    padding: 1rem 1rem 0rem;

    @include landscape-mode {
      padding: 0 1rem;
    }
  }

  &__directions {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;

    &-title {
      font-size: 2rem;
      padding: 1rem 0;
    }

    &-field {
      width: 100%;
      padding: 1rem;
      border: 1px solid $color-primary;
      position: relative;

      &.primary {
        background-color: $color-primary;
      }

      &-title {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: bold;
      }

      &-value {
        padding: 0.5rem 0 0 0;
        display: flex;
        flex-direction: column;

        &-primary {
          font-size: 2rem;
        }
        &-secondary {
          font-size: 1.2rem;
        }
      }

      &-input {
        padding: 0.5rem 0 0 0;
        display: flex;

        input {
          border: none;
          width: 100%;
          border: 1px solid $color-primary;
          font-size: 1.6rem;
          padding: 0.5rem;
          outline: none;
          height: 4rem;
        }

        &-search {
          @include ff-icon;
          background-color: $color-primary;
          border: none;
          color: $color-white;
          outline: none;
        }
      }

      &-overlay {
        position: absolute;
        background-color: rgba($color-white, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        font-size: 1.6rem;
        text-transform: uppercase;
      }

      &-error {
        font-size: 1.4rem;
        padding: 0.5rem 0 0 0;
        color: $color-sold;
      }
    }

    &-button {
      display: flex;
      padding: 1rem 0;
      justify-content: flex-start;
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem 0;

    &-title {
      font-size: 2rem;
      padding: 1rem 0;
    }
  }

  &__filter-item {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $color-grey-1;
    }

    &-icon {
      width: 5rem;
      height: 5rem;
      flex: 0 0 5rem;
      @include ff-icon-1;
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-grey-1;
      border-radius: 2.5rem;
      transition: all 0.2s ease-out;

      .is-active & {
        background-color: $color-primary;
        // color: $color-white;
      }

      @include hover {
        background-color: $color-highlight;
      }
    }

    &-title {
      font-size: 1.6rem;
      padding: 1rem;
      flex: 1 0 50px;
    }
  }
}
