@import "../../../scss/variables";

.apartment-slice-area-and-code {
    padding: 0 0;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;

    &__group {
        display: flex;
        flex-direction: column;
        text-align: center;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }

    &__label {
        font-size: 1.4rem;
        text-transform: lowercase;
        color: $color-grey-7;
    }

    &__value {
        font-size: 2.4rem;
        font-weight: 500;
        color: $color-grey-8;
    }
}
