@import "../scss/variables";

.apartments {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    @include landscape-mode() {
        flex-direction: row;
        justify-content: flex-end;
    }

    &__logo {
        width: 5rem;
        left: 1rem;
        top: 1rem;
        position: fixed;
        z-index: 110;

        @include landscape-mode {
            top: 1rem;
        }
    }

    &__iframe {
        width: 100%;
        height: 100%;
        border: none;
        background-color: black;
    }

    &__elevation-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__absolute-elements {
        position: absolute;
        z-index: 2;
        width: 100%;
        left: 0;
        top: $header-height-sm;
        bottom: $apartment-navigation-height-sm;
        background-color: rgba($color-white, 1);
        transition: background-color 0.2s ease-out;

        @include landscape-mode {
            bottom: 0;
            top: 0;
        }
    }

    &__viewer {
        // flex: 0 1 100vh * (4/3);
        z-index: 1;
        width: 100%;
        // padding-top: $header-height-sm + $apartment-navigation-height-sm;
        // height: 100vw * (3/4);

        &-element {
            // width: 100%;
            // display: block;
            position: relative;

            @include landscape-mode() {
                display: flex;
                flex: 0 1 100vh * (4/3);
            }
        }

        &-fake-sidebar {
            display: none;

            @include landscape-mode {
                display: flex;
                flex: 1 0 360px;
                width: 360px;

                &::before {
                    content: "";
                    width: 360px;
                }
            }
        }

        @include landscape-mode() {
            padding: 0;
            flex: 0 1 100vh * (4/3);
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            flex-wrap: nowrap;
        }
    }

    &__viewer-header {
        width: 100%;
        // display: flex;
        display: none;
        flex-direction: column;
        height: $apartment-navigation-height-sm;
        position: absolute;
        top: -$apartment-navigation-height-sm;
        background-color: $color-grey-1;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        z-index: 2;
        justify-content: center;
        align-items: flex-start;
        padding: 0 2rem;

        @include landscape-mode {
            display: none;
        }

        &-title {
            color: $color-primary;
            font-weight: bold;
            font-size: 2.4rem;
        }

        &-count {
            text-transform: uppercase;
            font-size: 1.4rem;
            padding-top: 0.2rem;
            color: $color-grey-8;

            b {
                font-size: 1.6rem;
                padding-right: 0.5rem;
            }
        }
    }

    &__sidebar {
        // flex: 1 0 360px;
        background-color: $color-grey-0;
        z-index: 2;
        // box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        border-top: 1px solid $color-grey-1;
        position: relative;

        &-fake-viewer {
            display: none;

            @include landscape-mode {
                display: flex;
                flex: 0 1 100vh * (4/3);
            }
        }

        @include landscape-mode() {
            flex: 1 0 360px;
            border: none;
        }
    }

    &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: $color-white;
        opacity: 0;
        z-index: 3;
        transition: opacity 0.5s ease-out;

        &.is-active {
            opacity: 1;
            transition: none;
        }
    }
}
