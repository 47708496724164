@import "../../scss/variables";

.apartment-list {
    width: 100%;
    min-height: 100%;
    z-index: 2;
    background-color: $color-grey-0;
    position: relative;
    // overflow-x: hidden;
    // overflow-y: scroll;

    @include landscape-mode() {
        box-shadow: 0 0 2rem 0 rgba(black, 0.3);
    }

    &__empty-info {
        padding: 2rem;
        text-align: center;
        font-size: 1.8rem;
        color: $color-black;
    }

    &__header {
        padding: 1rem 1rem 1rem 1rem;
        background-color: $color-grey-0;
        position: relative;
        top: 0;
        z-index: 7;
        border-bottom: 1px solid $color-grey-1;

        @include landscape-mode() {
            position: sticky;
            top: 0;
        }

        &-bar {
            width: 100%;
            // display: flex;
            display: none;
            justify-content: space-between;
            align-items: stretch;
            height: $header-height-sm;

            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            background-color: $color-white;
            padding: 2rem 2rem 2rem 7rem;
            box-shadow: 0 0 1rem 0 rgba(black, 0.5);

            @include landscape-mode() {
                position: relative;
                padding: 0;
                height: 6rem;
                box-shadow: none;
                background-color: $color-grey-1;
            }
        }

        &-title {
            padding: 1rem;
            font-size: 2.4rem;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: $color-black;

            @include breakpoint-sm-up {
                font-size: 3rem;
            }
        }

        &-icon {
            @include ff-icon;
            display: flex;
            align-items: center;
            padding: 1rem 0;
            font-size: 4rem;
            color: $color-black;
            cursor: pointer;

            @include landscape-mode {
                padding: 1rem;
            }
        }

        &-building {
            width: 100%;
            padding: 0.6rem 7rem 0rem 0;
            margin-left: -1px;
            font-size: 3rem;
            color: $color-black;
            font-weight: 500;
            text-transform: uppercase;

            @include not-landscape-mode {
                display: none;
            }
        }

        &-view-mode {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-button {
                display: flex;
                align-items: center;
                color: $color-grey-7;
                cursor: pointer;
                padding: 1rem;
            }

            &-icon {
                @include ff-icon;
                padding-right: 0.5rem;
                font-size: 2.5rem;
            }

            &-label {
                font-size: 1.4rem;
                text-transform: uppercase;
            }
        }

        &-free-apartments {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            color: $color-grey-7;
            padding: 0 0 2rem;
            text-transform: uppercase;

            @include not-landscape-mode {
                display: none;
            }

            b {
                font-size: 1.6rem;
                padding-right: 0.5rem;
            }
        }

        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: -2rem;
        //     left: 0;
        //     width: 100%;
        //     height: 2rem;
        //     background: linear-gradient(rgba($color-grey-0, 1), rgba($color-grey-0, 0));
        // }
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0.5rem 0rem 8rem;
        z-index: 1;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        &-wrapper {
            display: flex;
            padding: 2rem 1rem 8rem;
        }

        thead {
            th {
                text-align: left;
                font-size: 1.2rem;
                background-color: $color-grey-0;
                color: $color-grey-7;
                padding: 1rem;
                border-bottom: 2px solid $color-grey-3;
                text-align: center;
                text-transform: uppercase;
                // transform: rotate(-90deg);
                // transform-origin: left bottom;
                // height: 10rem;
                // width: 3rem;

                text-align: center;

                .title {
                    // width: 2rem;
                    height: 8rem;
                    position: relative;

                    @include not-landscape-mode-sm {
                        height: auto;
                    }

                    @include landscape-mode-wide {
                        height: auto;
                    }

                    &-rotate {
                        position: absolute;
                        bottom: 0;
                        left: calc(50% + 0.7rem);
                        transform: rotate(-90deg);
                        transform-origin: bottom left;

                        @include not-landscape-mode-sm {
                            position: relative;
                            left: unset;
                            transform: none;
                        }

                        @include landscape-mode-wide {
                            position: relative;
                            left: unset;
                            transform: none;
                        }
                    }
                }
            }
        }

        tbody {
            td {
                font-size: 1.3rem;
                padding: 1rem 0.2rem;
                text-align: center;

                &.cell-name {
                    font-weight: bold;
                    font-size: 1.3rem;
                    color: $color-primary;

                    &.is-reserved {
                        color: $color-reserved;
                    }

                    &.is-sold {
                        color: $color-sold;
                    }
                }
            }

            tr {
                cursor: pointer;
                &:nth-child(2n) {
                    background-color: $color-grey-1;
                }

                &:hover,
                &.is-hover {
                    background-color: rgba($color-primary, 0.6);
                }
            }
        }
    }

    &__card {
        width: 100%;
        padding: 0.5rem 1rem;
        display: flex;
        cursor: pointer;
        flex: 1 0 300px;

        &-fake {
            height: 0;
            flex: 1 0 300px;
        }

        &-left {
            flex: 1 0 35%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            background-color: $color-white;
        }

        &-right {
            flex: 0 1 65%;
            position: relative;
            padding: 1rem;
            background-color: $color-grey-2;
            background: url("../../assets/blur-2.png");
            background-size: cover;
        }

        &-content {
            // border: 1px solid $color-grey-1;
            // padding: 1rem;
            height: 100%;
            // box-shadow: 0 0.2rem 0.6rem 0 rgba(black, 0.3);
            box-shadow: $box-shadow-s;
            transition: all 0.2s ease-out;
            width: 100%;
            border-radius: 0.3rem;
            display: flex;
            overflow: hidden;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 5;
                pointer-events: none;
                border: 2px solid transparent;
                box-sizing: border-box;
                transition: all 0.2s ease-out;
                border-radius: 0.3rem;
            }
        }

        &-title {
            font-size: 2.4rem;
            color: $color-black;
            font-weight: bold;
            padding: 0 0 1rem 0;

            &-wrapper {
                display: flex;
                flex: 1 0 50%;
                justify-content: space-between;
                align-items: center;
            }
        }

        &-compare-button {
            width: 4rem;
            height: 4rem;
            @include ff-icon;
            flex: 0 0 4rem;
            // border: 1px solid $color-grey-5;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2rem;
            box-shadow: 0 0.1rem 0.5rem 0 rgba(black, 0.4);
            background-color: $color-white;
            color: $color-black;
            transition: all 0.2s ease-out;
            position: absolute;
            right: 1rem;
            top: 1rem;
            z-index: 2;

            &.is-selected {
                background-color: $color-primary;
                color: $color-white;
            }

            @media (hover: hover) {
                &:hover {
                    background-color: $color-highlight;
                    color: $color-white;
                    box-shadow: 0 0.1rem 0.5rem 0 rgba($color-secondary, 0.7);
                }
            }

            &:active {
                background-color: $color-highlight;
                color: $color-white;
                box-shadow: 0 0.1rem 0.5rem 0 rgba($color-secondary, 0.7);
            }
        }

        &-availability {
            font-size: 1.6rem;
            color: $color-primary;
            font-weight: 500;
            padding: 0 0 1rem 0;

            &.is-reserved {
                color: $color-reserved;
            }

            &.is-sold {
                color: $color-sold;
            }
        }

        &-layout {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-split {
            display: flex;
        }

        &-row {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            flex: 0 0 50%;
        }

        &-prop {
            display: flex;
            flex-direction: column;
            // flex: 1 0 50%;
            padding: 0.5rem 0;

            &-label {
                font-size: 1.2rem;
                text-transform: uppercase;
                color: $color-black;
            }

            &-value {
                font-size: 1.6rem;
                font-weight: 500;
                color: $color-black;
            }
        }

        @media (hover: hover) {
            &.is-hover {
                .apartment-list {
                    &__card {
                        &-content {
                            // box-shadow: 0 0.2rem 0.6rem 0 rgba(black, 0.3), inset0 0 0 1px $color-primary ;
                            &::after {
                                border-color: $color-primary;
                            }
                        }
                    }
                }
            }
        }
    }

    &__compare-button-wrapper {
        position: sticky;
        bottom: 0rem;
        padding: 2rem 1rem 2rem;
        display: flex;
        z-index: 2;
        justify-content: center;
        align-items: flex-end;
        pointer-events: none;
        height: 10rem;
        transition: all 0.2s ease-in-out;

        &.is-active {
            bottom: 0;
        }

        &::after {
            content: "";
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 8rem;
            background: linear-gradient(rgba($color-grey-0, 0), rgba($color-grey-0, 1));
            z-index: 1;
        }

        &-center {
            position: absolute;
            z-index: 2;
        }

        .button {
            pointer-events: all;
            z-index: 2;
        }
    }

    &__view-mode {
        &-title {
            padding: 0 0.5rem;
            font-size: 2rem;
            font-weight: 500;
            color: $color-black;
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 2.5rem;
            padding: 0rem 0.5rem;
            background-color: $color-white;
            box-shadow: $box-shadow-s;

            .field-wrapper {
                flex-grow: 0;
            }
        }
    }
}
