@import "../../scss/variables";

.apartment-filters {
    width: 100%;
    background-color: $color-white;
    box-shadow: $box-shadow-s;

    &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-grey-1;
    }

    &__title {
        padding: 1rem;
        font-size: 2rem;
        // text-transform: uppercase;
        color: $color-black;
        font-weight: 500;
    }

    &__short {
        padding: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: $color-black;
    }

    &__collapsable {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__columns {
        display: flex;
        flex-direction: row;
        position: relative;
        flex-wrap: wrap;
    }

    &__column {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 0 200px;
        align-content: flex-start;
        padding: 0.5rem;

        &-spacer {
            height: 1rem;
            width: 100%;
        }
    }

    &__check-fake {
        flex: 1 0 150px;
        height: 0;
    }

    &__header {
        flex: 0 0 100%;
        padding: 0.5rem 0.5rem 1rem;
        border-bottom: 1px solid $color-grey-1;
    }

    &__footer {
        flex: 0 0 100%;
        padding: 0.5rem;
        border-top: 1px solid $color-grey-1;
    }

    &__button {
        background-color: $color-grey-0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
        color: $color-black;
        cursor: pointer;

        &-icon {
            @include ff-icon;
            font-size: 2.4rem;

            &.is-collapsed {
                color: $color-primary;
            }
        }

        &-label {
            text-transform: uppercase;
            // font-weight: 500;
            font-size: 1.2rem;

            &.is-collapsed {
                color: $color-primary;
            }
        }
    }
}
