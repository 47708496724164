@import "../../../scss/variables";

.apartment-slice-room-list {
    flex: 1 0 200px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 1rem 0;
    // padding: 0 1rem;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: $color-grey-0;
        z-index: 2;
    }

    &__room {
        flex: 1 0 200px;
        padding: 0.3rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid $color-grey-2;
        font-size: 1.2rem;
        color: $color-grey-8;
    }

    &__label {
        flex: 1 0 90px;
    }

    &__area {
        flex: 0 0 60px;
        text-align: right;
        font-weight: bold;
    }

    &__empty {
        flex: 1 0 200px;
        height: 0;
    }
}
