@import "../scss/variables";

.static {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: $header-height-sm;
    min-height: 100%;
    align-items: stretch;

    &.menu {
        padding: 0;
    }

    @media (min-width: $breakpoint-md) {
        padding-top: $header-height-md;
    }

    &__header {
        position: fixed;
        width: 100%;
        height: $header-height-sm;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        z-index: 3;

        @media (min-width: $breakpoint-md) {
            height: $header-height-md;
        }

        &-left {
            display: flex;
            height: 100%;
            padding: 2rem 1rem;
            align-items: center;
            color: $color-black;

            @media (min-width: $breakpoint-md) {
                padding: 1rem;
            }

            .menu & {
                display: none;
            }
        }

        &-logo {
            height: 100%;
        }

        &-title {
            font-size: 3rem;
            text-transform: uppercase;
            font-weight: 500;
            padding: 1rem 1rem 1rem 2rem;
            color: $color-black;

            @include breakpoint-sm-up {
                font-size: 3rem;
            }
        }

        &-button {
            @include ff-icon;
            height: 100%;
            width: 7rem;
            // padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            cursor: pointer;
            text-align: center;

            .menu & {
                box-shadow: 0 0 1rem 0 rgba(black, 0.5);
                height: $header-height-sm - 3rem;
                width: $header-height-sm - 3rem;
                border-radius: $header-height-sm/2;
                background-color: $color-white;

                @include breakpoint-md-up {
                    height: $header-height-md - 2rem;
                    width: $header-height-md - 2rem;
                    font-size: 3rem;
                }
            }
        }

        .menu & {
            width: $header-height-sm - 3rem;
            height: $header-height-sm - 3rem;
            left: unset;
            right: 2rem;
            top: 2rem;
            background-color: transparent;
            box-shadow: none;

            @include breakpoint-md-up {
                width: $header-height-md - 2rem;
                height: $header-height-md - 2rem;
            }
        }
    }

    &__logo {
        height: 7rem;
        left: 1rem;
        top: 1rem;
        position: absolute;
        z-index: 10;
    }

    &__content {
        width: 100%;
        // height: 100%;;

        h1 {
            font-size: 3rem;
            padding: 0.25rem 0;
        }

        p {
            font-size: 1.6rem;
            padding: 0.25rem 0;
            line-height: 1.5em;
        }

        ul {
            padding: 0.25rem 0 0.25rem 2rem;
            font-size: 1.6rem;

            li {
                padding: 0.25rem 0;
                line-height: 1.25em;
            }
        }
    }
}
