.button {
    background-color: $color-white;
    border: 2px solid $color-white;
    border-radius: 6rem;
    color: $color-grey-7;
    padding: 1rem 2rem;
    text-transform: uppercase;
    margin: 0 0.5rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 400;
    @include ff-text;
    outline: none;
    box-shadow: $box-shadow-s;
    display: flex;
    align-items: center;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.small {
        text-transform: none;
        font-size: 1.2rem;
    }

    &.low {
        padding: 0.5rem 2rem;
        font-size: 1.4rem;
    }

    &.icon {
        @include ff-icon;
        padding: 0.5rem;
        font-size: 3rem;

        &.small {
            font-size: 1.6rem;
        }
    }

    &.white-green {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-primary;
    }

    .icon {
        @include ff-icon;
        font-size: 2rem;
        margin-right: 1rem;
    }

    &.is-active {
        border-color: $color-primary;
        // box-shadow: 0 0 1rem 0 $color-primary;

        &:active {
            background-color: $color-highlight-2;
            color: $color-white;
        }

        @media (hover: hover) {
            &:hover {
                color: $color-white;
                background-color: $color-primary;
                border-color: $color-primary;
            }
        }
    }

    &.is-on {
        border-color: $color-primary;
        background-color: $color-primary;
        color: $color-white;
    }

    @media (hover: hover) {
        &:hover {
            background-color: $color-primary;
            color: $color-white;
            border-color: $color-primary;
        }
    }

    &:active {
        background-color: $color-primary;
        color: $color-white;
        border-color: $color-primary;
    }
}

.button-menu {
    @include ff-icon;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background-color: $color-white;
    z-index: 5;
    border-radius: 50%;
    position: fixed;
    top: 1rem;
    right: 1rem;
    text-align: center;
    font-size: 3rem;
    box-shadow: $box-shadow-md;
    cursor: pointer;
}
