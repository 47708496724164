@import "../scss/variables";

.field-check {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    flex: 1 0 150px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.short {
        flex: 0 0 3.5rem;
    }

    &__box {
        width: 3.5rem;
        height: 2rem;
        flex: 0 0 3.5rem;
        // border: 2px solid $color-primary;
        display: flex;
        position: relative;
        margin-right: 0.5rem;
        background-color: $color-grey-1;
        border-radius: 1rem;
        transition: all 0.2s ease-in-out;

        &::before {
            content: "";
            position: absolute;
            width: 2rem;
            height: 2rem;
            border: 2px solid $color-grey-7;
            top: 0;
            left: 0;
            box-sizing: border-box;
            border-radius: 1rem;
            background-color: $color-white;
            transition: all 0.2s ease-in-out;
        }

        &.is-checked {
            background-color: $color-primary;

            &::before {
                border-color: $color-primary;
                left: 1.5rem;
            }
        }
    }

    &__label {
        font-size: 1.2rem;
        // font-weight: bold;
    }
}
