@import "../scss/variables";

.field-range {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    touch-action: none;
    user-select: none;

    &__track {
        width: 100%;
        background-color: $color-grey-3;
        height: 2px;
        position: absolute;
        z-index: 1;
        top: 9px;
    }

    &__selector {
        flex: 0 0 20px;
        max-width: 20px;
        display: flex;
        flex-direction: column;
        z-index: 4;
        position: relative;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &::before {
            content: "";
            position: absolute;
            top: -1rem;
            width: 30px;
            height: 50px;
        }

        &.right {
            &::before {
                left: 0;
            }
        }

        &.left {
            &::before {
                right: 0;
            }
        }

        &-circle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $color-white;
            border: 2px solid $color-grey-7;
            transition: border-color 0.2s ease-out;

            .is-changed & {
                border-color: $color-primary;
            }
        }

        &-label {
            width: 20px;
            text-align: center;
            padding: 0.2rem 0;
            color: $color-grey-7;
            font-weight: bold;
            font-size: 1.1rem;
            transition: color 0.2s ease-out;

            .is-changed & {
                color: $color-primary;
            }
        }
    }

    &__track-fill {
        flex: 1 0 1px;
        height: 2px;
        background-color: $color-grey-7;
        z-index: 2;
        margin-top: 9px;
        transition: border-color 0.2s ease-out;

        .is-changed & {
            background-color: $color-primary;
        }
    }
}
