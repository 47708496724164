@import "../../../scss/variables";

.static-slice-gallery-item {
    width: 50%;
    height: 50%;
    padding: 0.5rem;
    position: relative;
    cursor: pointer;

    &__photo {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.2s ease-out;

            @media (hover: hover) {
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    &__video {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.2s ease-out;

            @media (hover: hover) {
                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        &-play-icon {
            position: absolute;
            @include ff-icon;
            font-size: 6rem;
            color: $color-white;
            width: 8rem;
            height: 8rem;
            border-radius: 4rem;
            background-color: rgba(black, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        flex-direction: column;
        padding: 1rem;
        color: $color-white;
        font-size: 1vw;

        @include breakpoint-md-up {
            font-size: 0.5vw;
        }

        @include breakpoint-lg-up {
            font-size: 0.3333vw;
        }

        &-title {
            text-align: center;
            font-size: 6em;
            // text-transform: uppercase;
            font-weight: bold;
            padding: 0.3em;
            @include ff-hero;
            z-index: 3;
        }

        &-description {
            padding: 0.3em;
            font-size: 3.5em;
            text-align: center;
            z-index: 2;
            @include ff-text;
            font-weight: 300;
        }

        &-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
    }
}

.static-slice-gallery {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0.5rem;

    &__tile {
        position: relative;
        flex: 0 0 100%;
        // padding-top: 100%;

        @include breakpoint-md-up {
            flex: 0 0 50%;
        }

        @include breakpoint-lg-up {
            flex: 0 0 (100% / 3);
        }

        &-ratio {
            width: 100%;
            padding-top: 100%;
        }

        &-content {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            flex-wrap: wrap;

            &.type-1111 {
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            &.type-1122 {
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 100%;
                        height: 50%;
                    }
                    &:nth-child(2) {
                        width: 100%;
                        height: 50%;
                    }
                }
            }

            &.type-1212 {
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 50%;
                        height: 100%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                        height: 100%;
                    }
                }
            }

            &.type-1323 {
                flex-direction: column;
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 50%;
                        height: 50%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                        height: 50%;
                    }
                    &:nth-child(3) {
                        width: 50%;
                        height: 100%;
                    }
                }
            }

            &.type-1233 {
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 50%;
                        height: 50%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                        height: 50%;
                    }
                    &:nth-child(3) {
                        width: 100%;
                        height: 50%;
                    }
                }
            }

            &.type-1213 {
                flex-direction: column;
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 50%;
                        height: 100%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                        height: 50%;
                    }
                    &:nth-child(3) {
                        width: 50%;
                        height: 50%;
                    }
                }
            }

            &.type-1123 {
                .static-slice-gallery-item {
                    &:nth-child(1) {
                        width: 100%;
                        height: 50%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                        height: 50%;
                    }
                    &:nth-child(3) {
                        width: 50%;
                        height: 50%;
                    }
                }
            }
        }
    }

    &__viewer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $header-height-sm + 2rem 2rem 2rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 5;

        @include breakpoint-md-up {
            padding-top: $header-height-md + 2rem 2rem 2rem 2rem;
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(white, 0.95);
            z-index: 1;
        }

        &-display {
            z-index: 2;
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
        }

        &-photo {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            position: relative;
            align-items: center;

            &-img {
                // width: 100%;
                max-width: 100%;
                max-height: 100%;
                position: relative;
                object-fit: contain;
                box-shadow: 0 0 1rem 0 rgba(black, 0.5);
            }
        }

        &-youtube-iframe {
            position: absolute;
            background-color: $color-white;
            box-shadow: 0 0 1rem 0 rgba(black, 0.5);
            width: 100%;
            height: 100%;
            border: none;
            top: 0;
            left: 0;
        }

        &-video-player {
            position: absolute;
            background-color: $color-white;
            box-shadow: 0 0 1rem 0 rgba(black, 0.5);
            width: 100%;
            height: 100%;
            border: none;
            object-fit: cover;
            top: 0;
            left: 0;
        }

        &-player-content {
            width: 80%;
            padding-top: 80% * (1080/1920);
            position: relative;
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            @include ff-icon;
            z-index: 5;
            width: 4rem;
            height: 4rem;
            background-color: $color-white;
            box-shadow: 0 0 0.5rem 0 rgba(black, 0.5);
            border-radius: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    background-color: $color-highlight;
                }
            }
        }

        &-arrow {
            position: absolute;
            top: 0;
            width: 10%;
            height: 100%;
            z-index: 2;
            @include ff-icon;
            display: flex;
            align-items: center;
            font-size: 4rem;
            color: $color-black;
            cursor: pointer;

            &.left {
                left: 0;
                justify-content: flex-start;
            }

            &.right {
                right: 0;
                justify-content: flex-end;
            }
        }
    }
}
