@import "../../../scss/variables";

.apartment-details-slices {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 1rem 2rem;

    @include landscape-mode {
        padding: 0 0 2rem;
    }
}
