html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 9px;
    min-width: 320px;

    @media (min-width: $breakpoint-sm) {
        font-size: 10px;
    }

    @media (min-width: $breakpoint-md) {
        font-size: 10px;
    }
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    min-width: 320px;
    background-color: $color-white;
    @include ff-text();
    overflow-y: scroll;
    overflow-x: auto;
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.application {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

*::selection {
    background-color: transparent;
}

.router {
    width: 100%;
    height: 100%;
    position: relative;

    &.no-scroll {
        overflow: hidden;
    }

    .content {
        width: 100%;
        height: 100%;
    }
}
