@import "./colors";
@import "./fonts";

$breakpoint-sm: 500px;
$breakpoint-md: 750px;
$breakpoint-lg: 1000px;
$breakpoint-xlg: 1200px;

$dim-breakpoint-lg: 1280px;
$dim-breakpoint-xlg: 1600px;

$nav-max-width: 500px;
$nav-width-md: 320px;

$header-height-sm: 7rem;
$header-height-md: 7rem;
$apartment-navigation-width-md: 7rem;
$apartment-navigation-width-xlg: 9rem;
$apartment-navigation-height-sm: 8rem;

$static-width-max: 1200px;

$box-shadow-sm: 0 0 0.2rem 0 rgba(black, 0.3);
$box-shadow-s: 0 0 0.3rem 0 rgba(black, 0.3);
$box-shadow-md: 0 0 0.5rem 0 rgba(black, 0.3);
$box-shadow-lg: 0 0 1rem 0 rgba(black, 0.3);

@mixin hover() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

@mixin box-shadow-1() {
    box-shadow: 0 0 1rem 0 rgba(black, 0.5);
}

@mixin breakpoint-sm-up() {
    @media (min-width: $breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-md-up() {
    @media (min-width: $breakpoint-md) {
        @content;
    }
}

@mixin breakpoint-lg-up() {
    @media (min-width: $breakpoint-lg) {
        @content;
    }
}

@mixin breakpoint-xlg-up() {
    @media (min-width: $breakpoint-xlg) {
        @content;
    }
}

@mixin landscape-mode() {
    @media (min-width: $breakpoint-md) and (min-aspect-ratio: 7/6) {
        @content;
    }
}

@mixin landscape-mode-xlg() {
    @media (min-width: $breakpoint-xlg) and (min-aspect-ratio: 7/6) {
        @content;
    }
}

@mixin landscape-mode-wide {
    @media (min-width: $breakpoint-md) and (min-aspect-ratio: 14/6) {
        @content;
    }
    @media (min-width: $breakpoint-lg) and (min-aspect-ratio: 12/6) {
        @content;
    }
    @media (min-width: $breakpoint-xlg) and (min-aspect-ratio: 11/6) {
        @content;
    }
}

@mixin not-landscape-mode() {
    @media (max-width: $breakpoint-md) {
        @content;
    }
    @media (min-width: $breakpoint-md) and (max-aspect-ratio: 7/6) {
        @content;
    }
}

@mixin not-landscape-mode-sm() {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        @content;
    }
    @media (min-width: $breakpoint-md) and (max-aspect-ratio: 7/6) {
        @content;
    }
}

@mixin portrait-mode() {
    @media (min-width: $breakpoint-md) and (max-aspect-ratio: 7/6) {
        @content;
    }
}
