@import "../../../scss/variables";

.apartment-slice-location-info {
    flex: 0 0 100%;
    display: flex;
    position: relative;
    border: 1px solid $color-grey-2;
    margin: 1rem 0;
    padding: 1rem 0;

    &__element {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 50px;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        color: $color-grey-8;

        &:not(:last-child) {
            border-right: 1px solid $color-grey-2;
        }

        &-value {
            padding: 0.5rem;
            font-weight: bold;
        }
    }
}
