@import "../scss/variables";

@mixin nav-background($size) {
    width: $size * 2;
    height: $size * 2;
    top: calc(3rem - #{$size});
    right: calc(3rem - #{$size});
}

.nav {
    position: fixed;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    z-index: 20;

    &__background {
        position: fixed;
        background-color: $color-white;
        border-radius: 50%;
        transform-origin: 50% 50%;
        transition: opacity 0s 0s linear, transform 0.8s ease-out;
        box-shadow: 0 0 20rem 0 rgba(black, 0.1);
        transform: scale(1) translate3d(0, 0, 0);
        opacity: 1;
        z-index: 20;

        @include nav-background(150vw);

        @include not-landscape-mode {
            @include nav-background(150vh);
        }

        .is-closed & {
            transition: opacity 0.4s linear, transform 0s 0.4s ease-out;
            opacity: 0;
            transform: scale(0);
        }
    }

    &__close {
        position: fixed;
        z-index: 25;
        width: 5rem;
        height: 5rem;
        @include ff-icon;
        top: 1rem;
        right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        // box-shadow: 0 0 0.5rem 0 rgba(black, 0.3);
        border-radius: 3rem;
        opacity: 1;
        transition: all 0.3s 0.2s ease-out;
        cursor: pointer;
        background-color: $color-black;
        color: $color-white;

        .is-closed & {
            pointer-events: none;
            opacity: 0;
            transition: all 0.3s 0 ease-in;
        }
    }

    &__buttons {
        position: fixed;
        width: 100%;
        max-width: 360px;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 22;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 10rem 0 0;

        .is-closed & {
            pointer-events: none;
        }
    }

    &__link {
        $transition-hover: color 0.2s ease-out;
        padding: 1rem 2rem;
        font-size: 2.5rem;
        text-decoration: none;
        color: $color-black;
        text-transform: uppercase;
        transition: transform 0.2s 0.2s ease-out, $transition-hover, opacity 0.2s ease-out;
        width: 100%;
        text-align: right;
        // font-weight: bold;

        .is-closed & {
            transform: translate(110%, 0);
            opacity: 0;
        }

        &:hover {
            color: $color-highlight;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
                transition: transform 0.2s (0.1s + $i * 0.05s) ease-out, $transition-hover, opacity 0.2s ease-out;

                .is-closed & {
                    transition: transform 0.2s (10 - $i) * 0.05s 0.2s ease-out, $transition-hover, opacity 0.2s ease-in;
                }
            }
        }
    }

    &__photo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 21;
        pointer-events: none;
        transition: all 0.4s 0.5s ease-out;

        @include not-landscape-mode {
            display: none;
        }

        @include not-landscape-mode-sm {
            display: block;
        }

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-overlay {
            width: 500px;
            height: 500px;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 0 0 500px;
            background-color: $color-white;
            box-shadow: 0 0 3rem 0 rgba(black, 0.5);
        }

        .is-closed & {
            opacity: 0;
            transition: all 0.2s 0.2s ease-in;
        }
    }

    &__title {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 360px;
        z-index: 21;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 2rem;
        transition: all 0.2s 0.7s ease-out;

        &-1 {
            font-size: 4rem;
            @include ff-title;
            text-transform: uppercase;
            color: $color-black;
        }

        &-3 {
            font-size: 2.5rem;
            @include ff-title;
            color: $color-grey-7;
        }

        &-2 {
            font-size: 4rem;
            color: $color-black;
            margin-top: 4rem;
        }

        .is-closed & {
            opacity: 0;
            transition: all 0.1s ease-in;
        }
    }
}

// .nav {
//     position: absolute;
//     top: 0;
//     right: 0;
//     z-index: 20;
//     background-color: $color-white;
//     width: 100%;
//     min-height: 100%;
//     // height: 100%;
//     display: flex;
//     justify-content: center;
//     // pointer-events: none;

//     &.is-closed {
//         display: none;
//     }

//     @include landscape-mode {
//         width: 100%;
//         box-shadow: 0 0 1rem 0 rgba(black, 0.5);
//         position: fixed;
//         height: 100%;
//         transition: right 0.3s ease-out;
//         background-color: transparent;

//         &.is-closed {
//             display: flex;
//             // right: -110vw;

//             &.is-overlay {
//                 .nav__overlay {
//                     pointer-events: none;
//                     opacity: 0;
//                 }
//             }
//         }
//     }

//     &__center {
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         align-items: center;
//         z-index: 2;

//         @include landscape-mode {
//             overflow-x: visible;
//             overflow-y: auto;
//             position: relative;

//             box-shadow: 0 0 1rem 0 rgba(black, 0.5);
//             flex: 1 0 360px;
//             background-color: $color-white;
//         }
//         // max-width: 50rem;
//     }

//     &__top {
//         display: flex;
//         flex-direction: column;
//         padding: 2rem 0;
//         align-items: center;
//         width: 100%;
//         flex: 0 0 35rem;
//         justify-content: flex-start;
//     }

//     &__header {
//         width: 100%;
//         height: 6rem;
//         display: flex;
//         justify-content: flex-end;

//         &-close {
//             @include ff-icon;
//             font-size: 4rem;
//             padding: 0 2rem;
//             height: 100%;
//             display: flex;
//             align-items: center;
//             cursor: pointer;
//         }
//     }

//     &__title {
//         padding: 0 2rem;
//         font-size: 3rem;
//         text-transform: uppercase;
//         @include ff-title;
//         width: 100%;

//         &-small {
//             font-size: 2rem;
//         }

//         @media (min-width: $nav-max-width) {
//             max-width: $nav-max-width;
//         }
//     }

//     &__caption {
//         text-align: center;
//         font-size: 3rem;
//         padding: 2rem;
//         width: 100%;

//         @media (min-width: $nav-max-width) {
//             max-width: $nav-max-width;
//         }
//     }

//     &__middle {
//         flex: 1 0 35rem;
//         display: flex;
//         flex-direction: column;
//         padding: 2rem;
//         justify-content: center;
//         width: 100%;

//         @media (min-width: $nav-max-width) {
//             max-width: $nav-max-width;
//         }
//     }

//     &__link {
//         width: 100%;
//         background-color: $color-grey-1;
//         padding: 1rem 1.5rem;
//         font-size: 1.6rem;
//         margin-bottom: 2rem;
//         border-radius: 1.5rem;
//         border: 2px solid transparent;
//         text-align: center;
//         text-decoration: none;
//         color: $color-grey-7;
//         // font-weight: bold;
//         cursor: pointer;
//         transition: all 0.2s ease-in-out;
//         box-shadow: 0 0 1rem 0 $color-grey-5;
//         text-transform: uppercase;

//         &:hover {
//             background-color: $color-primary;
//             color: $color-white;
//         }

//         &.active {
//             border: 2px solid $color-primary;
//         }
//     }

//     &__bottom {
//         padding: 2rem;
//         text-align: center;
//         font-size: 1.2rem;
//         color: $color-grey-6;
//         display: flex;
//         flex: 0 0 6rem;
//         align-items: flex-end;
//         justify-content: center;
//     }
// }
