@import "../../../scss/variables";

.static-slice-image {
    display: flex;
    width: 100%;
    justify-content: center;

    &__image-wrapper {
        flex: 0 0 100%;
        position: relative;

        .right & {
            order: 2;
        }
    }

    &__image-content {
        width: 100%;
        position: relative;
        padding-top: 35%;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }

    &__text {
        flex: 0 0 50%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        align-items: center;

        .right & {
            order: 1;
            justify-content: flex-end;
        }
    }

    &__content {
        padding: 2rem;
        width: 100%;
        max-width: $static-width-max / 2;
        text-align: left;

        .right & {
            text-align: right;
        }
    }
}
