@import "../../../scss/variables";

.apartment-slice-availability {
    padding: 0;
    margin: 1rem 0;
    font-size: 2.4rem;
    font-weight: 500;
    color: $color-primary;
    flex: 0 0 100%;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include landscape-mode {
        margin-top: 0;
    }

    &.is-reserved {
        color: $color-reserved;
    }

    &.is-sold {
        color: $color-sold;
    }

    &__right {
        display: flex;
        flex: 1 0 10rem;
        justify-content: flex-end;
        align-items: center;
    }

    &__button {
        font-size: 1.2rem;
        text-align: right;
        padding: 0;
        text-decoration: none;
        color: $color-primary;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: all 0.2s ease-out;
        height: 4rem;
        overflow: hidden;
        opacity: 0;
        margin-right: 1rem;
        transform: translate(1rem, 0);
        pointer-events: none;
        font-weight: 500;

        &.is-visible {
            height: 4rem;
            opacity: 1;
            transform: translate(0, 0);
            pointer-events: all;
        }

        .icon {
            @include ff-icon;
        }
    }
}
