@import "../../scss/variables";

.apartment-details {
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 2;
    background-color: $color-grey-0;
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-grey-2;

    @include landscape-mode() {
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        // padding: 0 2rem;
        height: auto;
        min-height: 100%;
        border-top: none;
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-grey-0, 0);
        transition: background-color 0.2s ease-out;
        pointer-events: none;
        z-index: 5;

        &.is-active {
            transition: none;
            background-color: rgba($color-grey-0, 1);
        }
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        height: $header-height-sm;
        margin: 0;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        background-color: $color-white;
        padding: 0.5rem 7rem 0.5rem 7rem;
        justify-content: center;
        z-index: 8;
        font-size: 2rem;
        text-transform: uppercase;
        color: $color-black;

        @include landscape-mode() {
            position: sticky;
            padding: 1rem 7rem 1rem 1rem;
            font-size: 3rem;
            margin: 0;
            height: $header-height-md;
            box-shadow: none;
            background-color: $color-grey-0;
            justify-content: flex-start;
        }
    }

    &__go-back {
        display: none;
        width: 100%;
        align-items: center;
        text-decoration: none;
        color: $color-primary;
        font-size: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        border-bottom: 1px solid $color-grey-1;
        background-color: $color-grey-0;
        padding: 1rem 1rem;
        // background-color: $color-white;
        z-index: 3;
        cursor: pointer;
        // box-shadow: 0 0 1rem 0 rgba(black, 0.3);

        i {
            @include ff-icon;
            font-size: 3rem;
            padding: 0;
            text-align: left;
        }

        @include landscape-mode {
            border-bottom: 1px solid $color-grey-1;
            padding: 0 0 1rem 0;
            position: sticky;
            top: 0rem;
            display: flex;
        }
    }

    &__slices {
        width: 100%;
        position: relative;
        padding: 0 0;

        @include landscape-mode {
            padding: 0 1rem;
        }
    }

    &__buttons {
        position: relative;
        // bottom: 0;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: linear-gradient(
            rgba($color-grey-0, 0) 0%,
            rgba($color-grey-0, 0.8) 30%,
            rgba($color-grey-0, 1) 100%
        );

        @include landscape-mode {
            position: sticky;
            bottom: 0;
        }
    }

    &__compare {
        display: flex;
        // flex-wrap: wrap;
    }

    &__card-button {
        margin-bottom: 2rem;
    }
}
