@import "../../../scss/variables";

.static-slice-text {
    display: flex;
    width: 100%;
    justify-content: center;

    &__content {
        padding: 2rem;
        width: 100%;
        max-width: $static-width-max;
        text-align: justify;
    }
}
