@import "../scss/variables";

.start {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &__video {
        // position: absolute;
        width: 100%;

        @include landscape-mode {
            height: 100%;
        }
        // height: 100vw;

        z-index: 1;
        border: none;
        display: block;

        &-video {
            width: 100%;
            object-fit: cover;
            object-position: center center;
            display: block;

            @include landscape-mode {
                height: 100%;
            }
        }
    }

    &__logo {
        width: 5rem;
        left: 1rem;
        top: 1rem;
        position: fixed;
        z-index: 10;
    }

    &__viewer {
        // flex: 0 1 100vh * (4/3);
        z-index: 2;
    }

    &__sidebar {
        // flex: 1 0 500px;
        // height: 100%;
        // background-color: $color-white;
        z-index: 3;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        position: relative;

        .nav {
            background-color: rgba($color-white, 0.9);
        }
    }

    @media (min-width: $breakpoint-lg) {
        @media (min-aspect-ratio: 4/3) {
            flex-direction: row;
            justify-content: flex-end;

            &__video {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                &-video {
                    height: 100%;
                }
            }

            &__sidebar {
                flex: 0 0 $nav-width-md;
                height: 100%;
                display: flex;

                .nav {
                    min-height: 100%;
                }
            }
        }
    }
}
