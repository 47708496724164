@import "../scss/variables";

.field-radio-bar {
    display: flex;
    align-items: stretch;
    border: 1px solid $color-grey-2;
    height: 3rem;
    border-radius: 1.5rem;

    &__option {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        text-transform: uppercase;
        // font-weight: bold;
        color: $color-grey-8;
        cursor: pointer;
        padding: 0 0.7rem;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: all 0.2s ease-out;

        @include landscape-mode-wide {
            padding: 0 1.5rem;
        }

        &:not(:first-child) {
            border-left: 1px solid $color-grey-2;
        }

        &.is-selected {
            color: $color-primary;
            border-left-color: transparent;

            & + .field-radio-bar__option {
                border-left-color: transparent;
            }
        }

        &-icon {
            @include ff-icon-1;
            color: $color-grey-5;
            padding-right: 0.5rem;
            font-size: 2rem;

            .is-selected & {
                color: $color-primary;
            }
        }

        .wide & {
            padding: 0 1.5rem;
        }
    }

    &__thumb {
        position: absolute;
        width: 3rem;
        left: 0;
        top: 0;
        height: 3rem;
        border: 1px solid $color-primary;
        border-radius: 1.5rem;
        background-color: rgba($color-primary, 0.1);
        transition: all 0.2s ease-out;
    }
}
