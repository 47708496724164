@import "../../scss/variables";

.apartment-navigation {
    display: none;
    flex-direction: row;
    position: relative;
    width: 100%;
    // bottom: 0; // -$apartment-navigation-height-sm;
    height: $apartment-navigation-height-sm;
    // top: 20rem;
    // left: 0rem;
    z-index: 4;
    // box-shadow: 0 0 1rem 0 rgba(black, 0.5);

    transition: right 0.2s ease-in-out;
    border-top: 1px solid $color-grey-2;

    &.is-visible {
        display: flex;
    }

    @include landscape-mode {
        display: flex;
        position: absolute;
        top: 10rem;
        right: -($apartment-navigation-width-md + 2rem);
        flex-direction: column;
        border-top: none;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        width: $apartment-navigation-width-md;
        height: auto;
        border-radius: 2rem 0 0 2rem;
        backdrop-filter: blur(5px);

        &.is-visible {
            right: 0;
        }
    }

    @include landscape-mode-xlg {
        width: $apartment-navigation-width-xlg;
        right: -($apartment-navigation-width-xlg + 2rem);
    }

    &__button {
        flex: 1 0 60px;
        height: $apartment-navigation-height-sm;
        background-color: $color-grey-0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;

        &:nth-last-child(2) {
            display: none;
        }

        @include landscape-mode {
            // background-color: $color-white;
            background-color: rgba($color-grey-0, 0.8);
            &:first-child {
                border-radius: 2rem 0 0 0;
            }
            &:last-child {
                border-radius: 0 0 0 2rem;
            }
        }

       @include landscape-mode {
            &:nth-last-child(2) {
                display: flex;
            }
        }

        &:hover {
            background-color: $color-white;
        }

        &.is-active {
            background-color: $color-white;
            // color: $color-white;
        }

        &:not(:last-child) {
            border-right: 1px solid $color-grey-3;
        }

        &-icon {
            @include ff-icon-1;
            font-size: 4rem;

            @include landscape-mode {
                font-size: 3rem;
            }

            @include landscape-mode-xlg {
                font-size: 4rem;
            }
        }

        &-label {
            font-size: 0.9rem;
            text-align: center;
            font-weight: bold;
            padding: 0.5rem 0 0 0;
            text-transform: uppercase;

            @include landscape-mode {
                font-size: 0.8rem;
            }

            @include landscape-mode-xlg {
                font-size: 1rem;
            }
        }

        @include landscape-mode {
            flex: none;
            width: $apartment-navigation-width-md;
            height: $apartment-navigation-width-md;
            border-right: none;

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey-2;
            }
        }

        @include landscape-mode-xlg {
            width: $apartment-navigation-width-xlg;
            height: $apartment-navigation-width-xlg;
        }
    }
}
