@import "../../../scss/variables";

.apartment-slice-layout {
    flex: 0 0 100%;
    display: flex;
    height: 32rem;
    background-color: $color-grey-1;
    position: relative;
    margin: 1rem 0;
    background: url("../../../assets/blur-2.png");
    background-size: cover;

    &__img {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        object-fit: contain;
    }
}
