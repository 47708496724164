@import "../scss/variables";

.compare {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 100%;
    align-items: stretch;

    &__header {
        position: fixed;
        width: 100%;
        height: $header-height-sm;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: $color-white;
        box-shadow: $box-shadow-md;
        z-index: 3;

        @media (min-width: $breakpoint-md) {
            height: $header-height-md;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: 2;
            background-color: $color-white;
            width: 100%;
            height: $header-height-sm;
            top: 0;
            left: 0;
            border-bottom: 1px solid $color-grey-1;

            @include breakpoint-md-up {
                display: none;
            }
        }

        &-left {
            display: flex;
            height: 100%;
            padding: 1rem 1rem;
            align-items: center;
            z-index: 3;

            @media (min-width: $breakpoint-md) {
                padding: 1rem;
            }
        }

        &-right {
            z-index: 4;
            flex: 0 0 7rem;
        }

        &-buttons {
            display: flex;
            position: fixed;
            top: $header-height-sm;
            left: 0;
            width: 100%;
            height: $header-height-sm;
            align-items: center;
            justify-content: flex-end;
            background-color: $color-white;
            box-shadow: $box-shadow-lg;
            padding: 0 1rem;

            @include breakpoint-md-up {
                position: relative;
                top: unset;
                left: unset;
                width: unset;
                box-shadow: none;
                flex: 1 0 100px;
                justify-content: flex-end;
            }
        }

        &-logo {
            height: 100%;
        }

        &-title {
            font-size: 2.4rem;
            font-weight: 500;
            color: $color-black;
            text-transform: uppercase;
            padding: 1rem 1rem 1rem 2rem;

            @include breakpoint-sm-up {
                font-size: 3rem;
            }
        }

        &-button {
            @include ff-icon;
            height: 100%;
            width: 5rem;
            height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            cursor: pointer;
            box-shadow: $box-shadow-md;
            margin: 1rem;
        }
    }

    &__logo {
        height: 7rem;
        left: 1rem;
        top: 1rem;
        position: absolute;
        z-index: 10;
    }

    &__layout {
        width: 20rem;
        height: 25rem;
        object-fit: contain;
        margin: 0 2rem;
    }

    &__list {
        position: relative;
        padding: $header-height-sm * 2 0 0;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        min-height: 100%;
        box-shadow: $box-shadow-md;

        @media (min-width: $breakpoint-md) {
            padding: 7rem 0 0;
        }
    }

    &__table {
        height: auto;
        border-spacing: 0;
        position: relative;
        height: 100%;
        // border-collapse: collapse;

        // &::before {
        //     position: fixed;
        //     content: "";
        //     left: 0rem;
        //     width: 2rem;
        //     height: 100%;
        //     top: 0;
        //     background-color: $color-white;
        //     z-index: 30;
        // }

        thead {
            z-index: 20;

            th {
                font-size: 2rem;
                color: $color-black;
                padding: 2rem 1rem;
                text-align: center;
                position: sticky;
                top: $header-height-sm * 2;
                background-color: $color-grey-0;
                border-bottom: 1px solid $color-grey-2;

                @media (min-width: $breakpoint-md) {
                    top: 7rem;
                }

                &:first-child {
                    left: 0;
                    z-index: 20;
                }

                &:not(:last-child) {
                    border-right: 1px solid $color-grey-2;
                }

                &.darker {
                    background-color: $color-grey-1;
                }

                // &:not(:first-child) {
                //     border-left: 1px solid $color-grey-1;
                // }

                // &:nth-child(2n + 1):not(:nth-child(1)) {
                //     border-left: 1px solid $color-grey-1;
                // }
            }
        }

        tbody {
            z-index: 1;

            tr {
                &:hover {
                    td {
                        background-color: $color-grey-1;
                    }
                }
                &:not(:last-child) {
                    td {
                        &:not(:first-child) {
                            border-bottom: 1px solid $color-grey-1;

                            &.no-border-bottom {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            td {
                padding: 1rem 1rem;
                text-align: center;
                font-size: 1.6rem;
                background-color: $color-grey-0;

                &.darker {
                    background-color: $color-grey-1;
                }

                &:first-child {
                    text-align: right;
                    font-weight: bold;
                    position: sticky;
                    left: 0;
                    z-index: 10;

                    &.secondary {
                        font-weight: 400;
                    }
                }

                &:not(:last-child) {
                    border-right: 1px solid $color-grey-2;
                }

                // &:nth-child(2n + 1):not(:nth-child(1)) {
                //     background-color: $color-grey-1;
                // }
            }
        }
    }

    &__td-center {
        display: flex;
        justify-content: center;
        padding: 1rem 0.5rem;

        .button {
            &:first-child {
                margin-right: 2rem;
            }
        }
    }

    &__empty {
        position: relative;
        padding: 11rem 2rem 2rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-text {
            text-align: center;
            font-size: 2rem;
            padding-bottom: 3rem;
        }
    }

    &__add-more {
        flex: 1 0 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-0;
        background-image: url("../assets/loader-background.jpg");
        background-size: cover;
        flex-direction: column;
        padding-top: $header-height-sm;

        &-title {
            padding: 1rem 1rem 3rem 1rem;
            font-size: 2rem;
            max-width: 500px;
            text-align: center;
            color: $color-black;
        }
    }
}
