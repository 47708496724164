$color-highlight-2: rgb(44, 231, 178);
$color-highlight: rgb(0, 255, 183);
$color-primary: rgb(13, 230, 168);
// $color-primary: #46cca5;
$color-secondary: #0d545d;
$color-white: #fff;

$color-grey-0: rgb(245, 245, 245);
$color-grey-1: rgb(238, 238, 238);
$color-grey-2: rgb(224, 224, 224);
$color-grey-3: rgb(200, 199, 199);
$color-grey-4: rgb(187, 187, 187);
$color-grey-5: rgb(163, 163, 163);
$color-grey-6: rgb(141, 141, 141);
$color-grey-7: rgb(122, 122, 122);
$color-grey-8: rgb(71, 71, 71);

$color-black: rgb(71, 71, 71);

$color-reserved: rgb(255, 172, 19);

$color-sold: rgb(207, 3, 3);
